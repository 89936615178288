<template>
  <div class="cq-breadcrumb">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',
  }
</script>

<style scoped lang="stylus">
  .cq-breadcrumb
    @apply flex flex-row
    font-size 15px

    > *
      @apply no-underline
      @apply py-2
      font-weight 500

      &:not(:first-child):before
        content '›'
        @apply px-2 text-gray-400

</style>