import {
  isBefore,
  addDays,
  formatDistanceToNow,
} from 'date-fns'

import dateFnsLocaleMixin from '@/mixins/dateFnsLocale'

const isSubscriptionDateBeforeNow = (date) => {
  return isBefore(addDays(date, 1), new Date())
}

export default {
  mixins: [
    dateFnsLocaleMixin,
  ],
  methods: {
    getSubscriptionText(channel) {
      if (channel.is_paid_forever) {
        return this.$t('channel.subscription.forever')
      }
      if (channel.paid_until === undefined) {
        return this.$t('channel.subscription.noData')
      }
      const options = {locale: this.dateFnsLocale, addSuffix: true}
      const date = new Date(channel.paid_until)
      const formattedDate = formatDistanceToNow(date, options)
      if (isSubscriptionDateBeforeNow(date)) {
        return this.$t('channel.subscription.hasEnded', {formattedDate})
      }
      return this.$t('channel.subscription.endsIn', {formattedDate})
    },
    isSubscriptionEnded(channel) {
      return !channel.is_paid_forever
        && channel.paid_until !== undefined
        && isSubscriptionDateBeforeNow(new Date(channel.paid_until))
    }
  }
}
